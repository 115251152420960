:root {
    --default-color: #000;
}

.Showcase_top_header {
    background-color: var(--default-color);
    border-color: var(--default-color);
    height: 34px;
}

.Showcase_custom_home_container {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}

.Showcase_top_header .Showcase_top_head_content .Showcase_top_head_text {
    font-family: var(--font_1);
    font-size: 12px;
    font-weight: 600;
    line-height: 14.4px;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.showcase_center_header {
    padding: 15px 0px;
    border-bottom: 1px solid #E7E7E7;
    max-width: 1720px;
    margin: auto;
}

.showcase_center_header .showcase_center_header_left ul li {
    display: inline-block;
    font-family: var(--font_2);
    font-size: 14px;
    line-height: 14px;
    color: var(--default-color);
    margin-right: 15px;
    font-weight: normal;
    cursor: pointer;
}

.showcase_center_header .showcase_center_header_left ul li svg {
    margin-right: 3px;
    width: 16px;
    height: 16px;
}

.showcase_center_header .showcase_center_header_right .Vline {
    width: 1px;
    height: 20px;
    opacity: 0.15;
    background: var(--default-color);
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}

.showcase_center_header .showcase_center_header_right ul li {
    padding: 0;
}

.showcase_center_header .showcase_center_header_right ul li svg {
    margin-right: 15px;
}


.showcase_new_lang img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    object-fit: cover;
    margin: 0 15px;
}

.showcase_new_lang a img {
    width: 20px;
    height: 20px;
}

.showcase_new_lang div img {
    margin: 0;
}

.showcase_new_lang span {
    font-family: var(--font_2);
    font-size: 16px;
    font-weight: normal;
    color: var(--default-color);
}

.showcase_new_lang button::after {
    background: url('../images/down-arrow.png');
    border: 0;
    width: 10px;
    height: 5px;
    background-repeat: no-repeat;
    margin-left: 10px;
}

.showcase_top_header_menu a {
    display: inline-block;
        font-family: "Playfair Display";
        font-size: 18px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        /* text-align: center; */
        color: #000000;
        transition: all 200ms ease-in 50ms;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0;
        padding: 15px 25px;
    
}

.megaListCat ul li a {
    font-family: var(--font_1);
}

@media (min-width:1440px) and (max-width:1900px) {
    .showcase_center_header {
        max-width: 96%;
    }

}

@media (min-width:1280px) and (max-width:1439px) {
    .showcase_center_header {
        padding: 5px 0px;
        max-width: 1200px;
    }

    .showcase_center_header .showcase_center_header_left ul li {
        font-size: 13px;
    }
}

@media(max-width:768px) {
    .showcase_center_header {
        padding: 0px 10px;
        border-bottom: 0;
    }

    .Showcase_top_header .Showcase_top_head_content .Showcase_top_head_text {
        font-size: 10px;
        text-align: left;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .Showcase_custom_home_container {
        display: block;
    }
    
}

@media (min-width:1100px) and (max-width:1280px) {
    .showcase_center_header .showcase_center_header_left ul li {
        margin-right: 10px;
    }
}

@media (min-width:991px) and (max-width:1279px) {

    .showcase_center_header {
        padding: 0px 10px;
        border-bottom: 1px solid #E7E7E7;
    }

    .showcase_center_header .showcase_center_header_left ul li {
        font-size: 10px;
    }

    .showcase_center_header .showcase_center_header_right ul li svg {
        margin-right: 6px;
    }

    .showcase_top_header_menu a {
        font-size: 14px;
    }

    .showcase_new_lang {
        display: none !important;
    }

    .showcase_center_header .showcase_center_header_left ul li svg {
        margin-right: 3px;
    }

    

}

@media (min-width: 801px) and (max-width: 870px) {
    .showcase_center_header {
        padding: 10px;
    }
}

@media (min-width: 900px) and (max-width: 991px) {
    .showcase_center_header {
        padding: 10px;
    }
}

@media(min-width:769px) and (max-width: 990px) {
    .showcase_center_header {
        padding: 4px 10px;
    }
}

.DesktopSearch {
    position: absolute;
    width: 250px;
    top: -3px;
    left: -225px;
    transition: all .5s ease;
}

.DesktopSearch input {
    padding-left: 15px;
    font-size: 14px;
    height: 40px;
    transition: all .5s ease;
}

.showcase_center_header .DesktopSearch .DesktopSearchClose {
    right: -10px;
    width: auto !important;
    height: auto !important;
    color: var(--default-color);
    font-size: 18px;
}

@media (min-width:1280px) and (max-width:1439px) {
    .DesktopSearch {
        position: absolute;
        width: 200px;
        left: -170px;
    }
}

@media (min-width:991px) and (max-width:1279px) {
    .DesktopSearch {
        position: absolute;
        width: 200px;
        left: -179px;
        top: -10px;
    }
    .showcase_center_header .showcase_center_header_right .Vline {
        margin-right: 7px;
    }
}

@media(max-width:991px) {
    .showcase_new_lang img {
        margin-left: 0;
    }
}